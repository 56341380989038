import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from 'react-bootstrap/ListGroup';

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Welcome to Oasis Intelligence, where innovation meets expertise in software and web consulting.
            At Oasis Intelligence, we are passionate about empowering businesses to thrive in the digital age. With a team of seasoned professionals, we specialize in providing tailored solutions that drive growth, enhance efficiency, and elevate brand presence in the ever-evolving digital landscape.
            <br />
             <br />
          Our Approach:
          </p>
          <ListGroup as="ul" className="about-values" numbered>
            <ListGroup.Item as="li">
              <strong>Client-Centric Philosophy: </strong>
              We believe in building strong relationships with our clients to understand their unique needs and deliver solutions that exceed expectations.
            </ListGroup.Item>
            <ListGroup.Item as="li">
               <strong>Strategic Guidance: </strong>
               With years of experience in the industry, we offer strategic guidance to help you navigate the complexities of software and web development. Whether you're a startup looking to launch your first product or an established enterprise seeking to optimize your digital presence, we provide the expertise and insights you need to succeed.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Innovation and Creativity: </strong>
              Innovation is the driving force behind everything we do. We constantly explore emerging technologies, trends, and best practices to deliver cutting-edge solutions that set you apart from the competition. From AI-driven applications to immersive web experiences, we leverage the latest tools and techniques to fuel your success.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Quality and Exellence: </strong>
              At Oasis Intelligence, we are committed to delivering excellence in everything we do. From the initial consultation to the final delivery, we uphold the highest standards of quality and craftsmanship. Our meticulous attention to detail ensures that every project we undertake is executed flawlessly, exceeding your expectations every step of the way.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Continuous Improvement: </strong>
              The digital landscape is constantly evolving, and so are we. We embrace change and innovation, continuously seeking ways to improve and refine our processes to better serve our clients. By staying ahead of the curve, we empower you to stay ahead of the competition and achieve long-term success.
            </ListGroup.Item>
          </ListGroup>
          <br />
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
