import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import mobileLogo from "../../Assets/mobile2.png";
import BusinessLogo from "../../Assets/business.png";
import Software from "../../Assets/software-web.png";
import {ServiceCard} from '../Services';
import {
  mobile_title,
  mobile_description,
  mobile_path,
  business_title,
  business_description,
  business_path,
  software_title,
  software_description,
  software_path
} from "../../constants";

export function Services() {
  return (
    <Container fluid className="service-section">
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Services </strong>
        </h1>
        <p style={{color:'white'}}>
          At Oasis Intelligence, we offer a comprehensive range of services tailored to meet your business needs. From strategic consulting to custom software development, we're here to help you succeed in today's competitive market. Explore our services below to learn how we can support your business growth.
        </p>
        <hr/>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={mobileLogo}
              isBlog={false}
              title={mobile_title}
              description={mobile_description}
              navLink={mobile_path}
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={BusinessLogo}
              isBlog={false}
              title={business_title}
              description={business_description}
              navLink={business_path}
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={Software}
              isBlog={false}
              title={software_title}
              description= {software_description}
              navLink={software_path}          
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
