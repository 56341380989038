import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Samsar from "../../Assets/Projects/Samsar.png";
import Home from "../../Assets/Projects/Home.png";
import HomeMap from "../../Assets/Projects/Home-map.png";
import ContactFooter from "../Contact/ContactFooter";
import Android from "../../Assets/Projects/android.png";
import Ios from "../../Assets/Projects/apple.png";
function Projects() {
  return (
    <Container fluid className="project-section">
      <Container style={{color:'#fff'}}>
        <h1 className="project-heading">
          Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{color:'white'}}>
          Explore some of our recent projects to see how we've helped businesses like yours succeed in their digital transformation journey.
        </p>
        <hr/>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col md={4} className="project-card">
            <h1 className="project-heading">Samsar Mobile App</h1>
            <p style={{color:'white', textAlign:'left', marginBottom:20}}>
              <strong style={{textDecoration:'underline'}}>Project Description:</strong>
              {" "}
              <span>
                Samsar App is a cutting-edge mobile application designed to revolutionize the way ads for cars and real estate are managed and presented. With a sleek and intuitive interface, Samsar simplifies the process of buying, selling, and renting properties and vehicles.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20}}>
              <strong style={{textDecoration:'underline'}}>Features:</strong>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20, marginLeft:10}}>
              <strong style={{textDecoration:'underline'}}>Advanced Search:</strong>
              {" "}
              <span>
                Users can easily search for properties and vehicles based on their preferences, such as location, price range, and specifications.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20, marginLeft:10}}>
              <strong style={{textDecoration:'underline'}}>Interactive Listings:</strong>
              {" "}
              <span>
                Engaging listing pages showcase properties and vehicles with high-quality images, detailed descriptions, and key features.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20, marginLeft:10}}>
              <strong style={{textDecoration:'underline'}}>Adding Listing: </strong>
              {" "}
              <span>
                Users can seamlessly add their listings for cars or real estate, providing all necessary details including images, descriptions, and contact information.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20, marginLeft:10}}>
              <strong style={{textDecoration:'underline'}}>Managing Your Own Listing: </strong>
              {" "}
              <span>
                Sellers can manage their own listings, edit details, update prices, and mark listings as sold or rented as needed.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20, marginLeft:10}}>
              <strong style={{textDecoration:'underline'}}>Get in Touch with Listing Owner: </strong>
              {" "}
              <span>
                Interested buyers or renters can directly contact the listing owner through the app, facilitating communication and streamlining the transaction process.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20, marginLeft:10}}>
              <strong style={{textDecoration:'underline'}}>User Profiles: </strong>
              {" "}
              <span>
                Create personalized user profiles to save favorite listings, track activity, and manage preferences.
              </span>
            </p>
            <p style={{color:'white', textAlign:'left', marginBottom:20}}>
              <strong style={{ marginBottom:20}}>Try Samsar App: </strong>
              {" "}
              <p style={{paddingTop:10}}>
                <span>
                  <a href="https://play.google.com/store/apps/details?id=com.samsar">
                    <img src={Android} alt="android" height={53} width={150} />
                  </a>
                  <a href="https://apps.apple.com/us/app/samsar/6444816353">
                    <img src={Ios} alt="ios" height={50} width={150} />
                  </a>
                </span>

              </p>
            </p>
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "10px" }}
            className="about-img"
          >
            <img src={Samsar} alt="samsar" height={300} styles={{marginRight:40}} />
            <img src={Home} alt="samsar-home" height={300} styles={{marginLeft:40}}/>
            <img src={HomeMap} alt="samsar-home" height={300} styles={{marginLeft:40}}/>
          </Col>
          <h3>Want to learn more about Samsar?</h3>
          <p>Contact us today to discover how Samsar can transform the way you buy, sell, or rent properties and vehicles.
          </p>
          <ContactFooter/>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
