import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {SoftwareCard} from "./SoftwareCard";
import laptopImg from "../../Assets/software-web.png";

export function Software() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "20px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Software and Web App <strong className="purple">Solutions</strong>
            </h1>
            <SoftwareCard />
          </Col>
          <Col
            md={5}
            className="service-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
          <p style={{marginTop:-30}}>Ready to take your business to the next level with our comprehensive business solution services? Contact us today to schedule a consultation and discover how we can help you achieve your goals.
          </p>
        </Row>

      </Container>
    </Container>
  );
}
