export const  mobile_description = "In today's digital era, mobile presence is vital. At Oasis Intelligence, we specialize in crafting tailored mobile solutions for all business sizes. Our expertise ensures engaging experiences that deliver results.";
export const  mobile_title = "Mobile App Development";
export const mobile_path = "/services/mobile-app-development";

export const  business_description = "Business automation services leverage technology to streamline operations, boost efficiency, and cut costs. They automate tasks, enhance productivity, and improve decision-making based on data insights.";
export const  business_title = "Business Intelligence";
export const business_path = "/services/business-automation";

export const  software_description = "Software and web app services provide tailored solutions for businesses, boosting efficiency and scalability while improving user experience to drive growth in the digital realm.";
export const  software_title = "Software and Web Apps";
export const software_path = "/services/software-web-app";

