import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about1.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "5px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Who <strong className="purple">We are</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "10px" }}
            className="about-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
            {/* <img src={laptopImg2} alt="about" className="img-fluid" width='100%' styles={{marginTop:50}}/> */}
          </Col>
          <p className="about-footer">At Oasis Intelligence, we are more than just a software and web consulting firm. We are your trusted partner in digital transformation, dedicated to helping you unlock your full potential and achieve your business objectives.
            Contact us today to learn more about how we can help you harness the power of technology to drive your business forward.
          </p>
        </Row>

      </Container>
    </Container>
  );
}

export default About;
