import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Your Tech Partner",
          "Software Developement",
          "Business Automation",
          "Infrastructure Management",
          "Business Analysis",
          "Mobile App Development",
          "Web Development",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
