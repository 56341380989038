import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

const ContactPage = () => {
  return (
     <Container fluid className="contact-section">
      <Container style={{color:'#fff'}}>
        <h1 className="project-heading">
          Contact <strong className="purple">Us </strong>
        </h1>
        <p style={{color:'white'}}>
          Have questions or interested in working with us? We'd love to hear from you! Get in touch using the contact information below or fill out the form, and we'll get back to you as soon as possible.
        </p>
        <hr/>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <FaMapMarkerAlt size={40} style={{border:'1px solid #fff', padding:10, borderRadius:30}}/>
            <p style={{marginTop:20}}><strong >ADDRESS</strong></p> 
            <p style={{marginTop:20}}>
                <span>
                    Orlanod, FL
                </span>
            </p>
          </Col>

          <Col md={4} className="project-card">
            <FaPhone size={40} style={{border:'1px solid #fff', padding:10, borderRadius:30}}/>
            <p style={{marginTop:20}}><strong >PHONE</strong></p>
            <p style={{marginTop:20}}>
                <span>
                    (862) 571-1002
                </span>
            </p>
          </Col>

          <Col md={4} className="project-card">
            <FaEnvelope size={40} style={{border:'1px solid #fff', padding:10, borderRadius:30}}/>
            <p style={{marginTop:20}}><strong >EMAIL</strong></p>
            <p style={{marginTop:20}}>
                <span>
                    info@oasisintel.com
                </span>
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ContactPage;
