import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from 'react-bootstrap/ListGroup';

export function BusinessCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Welcome to Oasis Intelligence, your trusted partner for comprehensive business solutions tailored to meet your specific needs. In today's dynamic business landscape, organizations face a myriad of challenges, from increasing competition to evolving customer demands. That's where we come in, offering a range of services designed to help you overcome obstacles, optimize processes, and achieve your business goals.
            <br />
             <br />
          Our Business Solution Services:
          </p>
          <ListGroup as="ul" className="about-values" numbered>
            <ListGroup.Item as="li">
              <strong>Strategic Consulting:  </strong>
              Gain a competitive edge with our strategic consulting services. Our experienced consultants work closely with you to understand your business objectives, challenges, and opportunities. Whether you're looking to expand into new markets, improve operational efficiency, or enhance customer experience, we provide strategic guidance and actionable insights to drive success.
            </ListGroup.Item>
            <ListGroup.Item as="li">
               <strong>Digital Transformation: </strong>
               Embrace the power of digital technology to transform your business. Our digital transformation services encompass a wide range of solutions, including cloud migration, software modernization, and digital marketing. We help you harness the latest technologies to streamline processes, improve agility, and deliver value to your customers.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Custom Software Development: </strong>
              Leverage custom software solutions tailored to your unique business requirements. Our team of skilled developers specializes in creating bespoke software applications that address your specific needs and challenges. Whether you need a customer relationship management (CRM) system, enterprise resource planning (ERP) software, or a custom web application, we deliver scalable, high-performance solutions that drive efficiency and innovation.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Business Process Optimization: </strong>
              Streamline your workflows and maximize efficiency with our business process optimization services. We analyze your existing processes, identify bottlenecks and inefficiencies, and implement tailored solutions to improve productivity and reduce costs. From automation and integration to workflow redesign and performance monitoring, we help you optimize your operations for maximum effectiveness.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Data Analytics & Business Intelligence: </strong>
             Unlock the power of data to make informed decisions and drive business growth. Our data analytics and business intelligence services enable you to collect, analyze, and visualize data from various sources to gain valuable insights into your business performance, customer behavior, and market trends. With our advanced analytics solutions, you can identify opportunities, mitigate risks, and drive strategic decision-making.
            </ListGroup.Item>
             <p>
                Why Choose Us?
            </p>
            <ListGroup.Item as="li">
              <strong>Expertise: </strong>
              Our team consists of seasoned professionals with extensive experience in business consulting, technology, and software development.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Customized Solutions: </strong>
              We believe in building strong relationships with our clients to understand their unique needs and deliver solutions that exceed expectations.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Customized Solutions: </strong>
              We take a personalized approach to each project, tailoring our solutions to meet your specific needs and objectives. 
              Collaborative Approach: We believe in building strong partnerships with our clients, working closely with you to understand your business and deliver solutions that exceed your expectations.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Commitment to Quality: </strong>
              We are committed to delivering excellence in everything we do, from initial consultation to ongoing support.
            </ListGroup.Item>
          </ListGroup>
          <br />
        </blockquote>
      </Card.Body>
    </Card>
  );
}
