import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import homeLogo from "../../Assets/home-img-2.png";
import mobileLogo from "../../Assets/mobile2.png";
import BusinessLogo from "../../Assets/business.png";
import Software from "../../Assets/software-web.png";
import ProjectCard from "../Projects/ProjectCards";
import { CgWebsite } from "react-icons/cg"
import Type from "./Type";
import {
  mobile_title,
  mobile_description,
  mobile_path,
  business_title,
  business_description,
  business_path,
  software_title,
  software_description,
  software_path
} from "../../constants";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row className="first-home-row">
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Welcome!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                <strong className="main-name"> Oasis Intelligence </strong>
              </h1>

              <div className="home-type">
                <Type />
              </div>
               <Button
                href="/services"
                className="home-button"
              >
                <CgWebsite /> &nbsp;
                {"Learn More about our Services"}
              </Button>
            </Col>

            <Col md={5} className="home-image">
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
          <hr />
          <Row className="second-home-row">
            <Col md={4}>
              <ProjectCard
                imgPath={mobileLogo}
                isBlog={false}
                title={mobile_title}
                description={mobile_description}
                navLink={mobile_path}
              />
            </Col>
            <Col md={4}>
              <ProjectCard
                imgPath={BusinessLogo}
                isBlog={false}
                title={business_title}
                description={business_description}
                navLink={business_path}
              />
            </Col>
            <Col md={4}>
              <ProjectCard
                imgPath={Software}
                isBlog={false}
                title={software_title}
                description= {software_description}
                navLink={software_path}
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </section>
  );
}

export default Home;
