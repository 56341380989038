import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";

export function ServiceCard(props) {
  return (
    <Card className="service-card-view" href={props.navLink}>
        <img src={props.imgPath} alt="card-img" width={50} height={50} style={{border:'1px solid #fff', padding:5, borderRadius:25}} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
          <Button
            href={props.navLink}
            className="project-button"
          >
            <CgWebsite /> &nbsp;
            {"Learn More"}
          </Button>
      </Card.Body>
    </Card>
  );
}
