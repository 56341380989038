import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from 'react-bootstrap/ListGroup';

export function SoftwareCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Welcome to Oasis Intelligence, we offer a comprehensive range of software and web app services to help businesses thrive in the digital age. From custom software development to web application design, we're here to turn your ideas into reality and empower your business for success.
            <br />
             <br />
          Our Software Services:
          </p>
          <ListGroup as="ul" className="about-values" numbered>
            <ListGroup.Item as="li">
              <strong>Custom Software Development: </strong>
              Transform your business with tailored software solutions designed to meet your unique needs and challenges. Our expert team of developers will work closely with you to understand your requirements and deliver custom software that drives efficiency, productivity, and growth.
            </ListGroup.Item>
            <ListGroup.Item as="li">
               <strong>Web Application Development: </strong>
               Create powerful web applications that engage users and deliver exceptional experiences. Whether you need an e-commerce platform, a content management system, or a customer portal, we have the expertise to develop scalable and feature-rich web apps that propel your business forward.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>UI/UX Design: </strong>
              Make a lasting impression with stunning user interfaces and intuitive user experiences. Our talented designers will collaborate with you to create captivating designs that captivate your audience and enhance usability, ensuring your software and web apps stand out from the competition.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Desktop App Development: </strong>
              Empower your business with desktop applications that offer seamless performance and functionality across various operating systems. From Windows and macOS to Linux, we specialize in developing desktop apps that streamline processes, enhance productivity, and deliver tangible results.
            </ListGroup.Item>
             <p>
                Why Choose Us?
            </p>
            <ListGroup.Item as="li">
              <strong>Expertise: </strong>
              Our team consists of seasoned professionals with extensive experience in business consulting, technology, and software development.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Customized Solutions: </strong>
              We believe in building strong relationships with our clients to understand their unique needs and deliver solutions that exceed expectations.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Customized Solutions: </strong>
              We take a personalized approach to each project, tailoring our solutions to meet your specific needs and objectives. 
              Collaborative Approach: We believe in building strong partnerships with our clients, working closely with you to understand your business and deliver solutions that exceed your expectations.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Commitment to Quality: </strong>
              We are committed to delivering excellence in everything we do, from initial consultation to ongoing support.
            </ListGroup.Item>
          </ListGroup>
          <br />
        </blockquote>
      </Card.Body>
    </Card>
  );
}
