import React from 'react';
import { Container, Row } from "react-bootstrap";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from 'react-icons/fa';

const ContactFooter = () => {
  return (
    <Container style={{marginBottom:20}}>
        <Row>
            <div className="contact-item">
                <FaEnvelope className="contact-icon" />
                <span style={{marginLeft:10}}>info@oasisintel.com</span>
            </div>
            <div className="contact-item">
                <FaPhone className="contact-icon" />
                <span style={{marginLeft:10}}>36 00 00 34</span>
            </div>
            <div className="contact-item">
                <FaMapMarkerAlt className="contact-icon" />
                <span style={{marginLeft:10}}>Nouakchott, Mauritania</span>
            </div>
        </Row>
    </Container>
  );
};

export default ContactFooter;
