import React from "react";
import Card from "react-bootstrap/Card";
import CardImgOverlay from 'react-bootstrap/CardImgOverlay'
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";

function ProjectCards(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" width={200} height={250} />
      <CardImgOverlay>
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
          <Button
            href={props.navLink}
            className="project-button"
          >
            <CgWebsite /> &nbsp;
            {"Learn More"}
          </Button>
      </Card.Body>
      </CardImgOverlay>
    </Card>
  );
}
export default ProjectCards;
