import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {MobileCard} from "./MobileCard";
import laptopImg from "../../Assets/about1.png";

export function Mobile() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "5px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Mobile App <strong className="purple">Development</strong>
            </h1>
            <MobileCard />
          </Col>
          <Col
            md={5}
            className="service-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
          </Col>
          <p className="about-footer">Ready to take your business to the next level with a powerful mobile app? Get in touch with us today to discuss your project requirements and let's turn your ideas into reality!
          </p>
        </Row>

      </Container>
    </Container>
  );
}
