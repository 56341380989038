import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from 'react-bootstrap/ListGroup';

export function MobileCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Welcome to Oasis Intelligence, where we specialize in cutting-edge mobile development solutions tailored to meet your business needs. With the ever-growing demand for mobile applications, we're here to help you stay ahead of the curve and reach your target audience effectively.
            <br />
             <br />
          Our Mobile Development Services:
          </p>
          <ListGroup as="ul" className="about-values" numbered>
            <ListGroup.Item as="li">
              <strong>Cross-Platform Development: </strong>
              Want to reach both iOS and Android users with a single codebase? Our cross-platform development expertise enables us to build cost-effective, time-efficient apps using frameworks like React Native and Flutter, ensuring consistent performance across different platforms.
            </ListGroup.Item>
            <ListGroup.Item as="li">
               <strong>UI/UX Design: </strong>
               We believe that a seamless user experience is key to the success of any mobile app. Our skilled designers focus on creating intuitive interfaces and engaging user experiences that keep your audience coming back for more.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Quality Assurance & Testing: </strong>
              Ensuring the reliability and performance of your mobile app is our top priority. Our dedicated QA team rigorously tests every aspect of your app to identify and fix any bugs or issues, guaranteeing a smooth and error-free user experience.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>App Maintenance & Support: </strong>
              Our partnership doesn't end after the app launch. We provide ongoing maintenance and support services to keep your app up-to-date, secure, and fully functional, allowing you to focus on growing your business while we take care of the technical aspects.
            </ListGroup.Item>
            <p>
                Why Choose Us?
            </p>
            <ListGroup.Item as="li">
              <strong>Continuous Improvement: </strong>
              Our team comprises seasoned professionals with extensive experience in mobile development, ensuring top-notch solutions tailored to your requirements.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Client-Centric Approach: </strong>
              We believe in building strong relationships with our clients to understand their unique needs and deliver solutions that exceed expectations.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Customized Solutions: </strong>
              We understand that every business is unique, which is why we take a personalized approach to mobile app development, delivering solutions that align with your goals and objectives.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Timely Delivery:  </strong>
              We value your time and strive to deliver projects on time and within budget, without compromising on quality.
            </ListGroup.Item>
            <ListGroup.Item as="li">
              <strong>Transparent Communication: </strong>
              We keep you informed at every stage of the development process, ensuring transparency and clarity in our communication.
            </ListGroup.Item>
          </ListGroup>
          <br />
        </blockquote>
      </Card.Body>
    </Card>
  );
}
