import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {BusinessCard} from "./BusinessCard";
import laptopImg from "../../Assets/business.png";

export function Business() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "5px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Business <strong className="purple">Solutions</strong>
            </h1>
            <BusinessCard />
          </Col>
          <Col
            md={5}
            className="service-img"
          >
            <img src={laptopImg} alt="about" className="img-fluid" />
            {/* <img src={laptopImg2} alt="about" className="img-fluid" width='100%' styles={{marginTop:50}}/> */}
          </Col>
          <p className="about-footer">Ready to take your business to the next level with our comprehensive business solution services? Contact us today to schedule a consultation and discover how we can help you achieve your goals.
          </p>
        </Row>

      </Container>
    </Container>
  );
}
